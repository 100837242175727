import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';

import { AbstractBaseComponent } from '@shared/abstract-base/abstract-base.component';
import { Logger } from '@utils/logger';
import { AppStateService } from '@services/app-state.service';
import { Page } from '@bloomreach/spa-sdk/';
import { ButtonBlock, ButtonCollection, Link } from '@types';
import { LinkService } from '@services/link.service';
import { AnalyticsService } from '@frk/eds-components';
import { SegmentService } from '@services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { extractFileInfoFromLink } from '@utils/link-utils';

/**
 * Logger
 */
const logger = Logger.getLogger('ButtonsBlockComponent');

@Component({
  selector: 'ft-buttons-block',
  templateUrl: './buttons-block.component.html',
  styleUrls: ['./buttons-block.component.scss'],
})
export class ButtonsBlockComponent
  extends AbstractBaseComponent
  implements OnInit, OnChanges, OnDestroy {
  /**
   * Content to render
   */
  @Input() content: Array<ButtonCollection>;

  buttonsCollection: any;

  @Input() page: Page;

  private unsubscribe$: Subject<void> = new Subject<void>();

  /**
   * Constructor
   */
  constructor(
    private appStateService: AppStateService,
    private ctaLinkService: LinkService,
    private analyticsService: AnalyticsService,
    private segmentService: SegmentService
  ) {
    super();
  }

  /**
   * On Init
   */
  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnChanges(): void {
    this.buttonsCollection = this.content;
    this.buttonsCollection = this.getContent();
  }

  private trackEvent(displayTxt: string, linkUrl: string): void {
    this.analyticsService.trackEvent({
      event: 'linkOrButtonClick',
      detailed_event: 'Link or Button Click',
      event_data: {
        category: '',
        link_id: '',
        link_text: displayTxt,
        link_type: this.analyticsService.getLinkType(linkUrl),
        link_url: linkUrl,
      },
      link_text: displayTxt,
      link_url: linkUrl,
    });
  }

  public checkSignIn(
    event: Event,
    linkCompound: Link,
    downloadLocation?: string
  ): void {
    if (linkCompound?.enableDownloadTracking) {
      this.downloadTrackEvent(linkCompound?.displayText, linkCompound?.url);
    } else {
      this.trackEvent(linkCompound?.displayText, linkCompound?.url);
    }

    this.ctaLinkService.setUpdatedLinkData(downloadLocation);
    if (this.ctaLinkService.isLinkSignIn(linkCompound)) {
      this.ctaLinkService.openSignInLink(event, linkCompound);
    }
  }

  private getContent(): Array<ButtonBlock> {
    const contents = this.buttonsCollection.buttons;
    const newContent: Array<ButtonBlock> = [];
    let isSegmentSet = false;
    this.segmentService
      .isSegmentSet$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((isSet: boolean) => {
        isSegmentSet = isSet;
      });
    if (contents) {
      for (const content of contents) {
        if (content.linkCompound?.linkCollection?.length) {
          const linkCompound = content.linkCompound.linkCollection[0];
          const widenDocumentParams = this.ctaLinkService.getWidenDocumentParams(
            linkCompound.widenAssetCompound?.widenDocument
          );
          newContent.push({
            ...content,
            linkCompound,
            ctaText: linkCompound?.displayText,
            external: linkCompound?.external,
            externalSuffix: linkCompound?.externalSuffix,
            externalLinkClass: this.getExternalClass(linkCompound),
            target: linkCompound?.target,
            signInRequired: linkCompound?.signInLink,
            hideLockIcon: linkCompound?.hideLockIcon,
            href: this.ctaLinkService.getHref(
              this.page,
              linkCompound,
              isSegmentSet ? this.appStateService?.getHomePageUrl() : undefined
            ),
            downloadLocation: this.ctaLinkService.getDownloadLocationHref(
              this.page,
              linkCompound,
              this.appStateService?.getHomePageUrl()
            ),
            dataDocId: widenDocumentParams?.id.shift(),
            noindex: widenDocumentParams?.noindex,
            dataTitle: widenDocumentParams?.document_title,
            externalId: widenDocumentParams?.external_id,
          });
        }
      }
    }
    logger.debug(newContent);
    return newContent;
  }

  private getExternalClass(linkCompound: ButtonBlock): string {
    let externalLink = '';
    if (linkCompound?.external) {
      externalLink = 'external-link';
      if (linkCompound?.externalSuffix) {
        externalLink = externalLink + '--' + linkCompound?.externalSuffix;
      }
      return externalLink;
    }
    return externalLink;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * Track analytics download event when the link is clicked.
   */
  private downloadTrackEvent(displayTxt: string, linkUrl: string): void {
    const targetUrl = linkUrl;
    const {
      fileName,
      fileExtension,
    }: { fileName: string; fileExtension: string } = extractFileInfoFromLink(
      targetUrl
    );
    this.analyticsService.trackEvent({
      event: 'file_download',
      detailed_event: 'Download Link Clicked',
      link_text: displayTxt,
      link_url: targetUrl,
      file_name: fileName,
      file_extension: fileExtension,
      file_lit_code: '',
      location: '',
      event_data: {
        file_extension: fileExtension,
        file_lit_code: '',
        file_name: fileName,
        link_text: displayTxt,
        link_url: targetUrl,
        location: '',
      },
    });
  }
}
