import { Injectable } from '@angular/core';
import { IdentifierKey } from '@models';
import { PerformanceComponentId } from '@products/fund-performance/performance-component-id.enum';
import { AppStateService, AuthenticationType } from '@services';
import {
  AssetClass,
  CalcTypeStd,
  Channel,
  ChartCalculationType,
  CommonConfig,
  ConfigurationCountry,
  ConfigurationId,
  PriceDistribution,
  FundId,
  FundIdentifier,
  FundSectionLink,
  FundShareClassId,
  GlobalConfig,
  HoldingTableColumns,
  LiteratureAuditConfig,
  LiteratureConfig,
  LiteraturePageLayoutConfig,
  LoginMaintenanceSettings,
  MifidAge,
  PerformanceConfig,
  PerformanceSuppressionSet,
  PerformanceType,
  PerformanceView,
  PerformanceViewOption,
  PortfolioComponentId,
  ProductConfig,
  ProductTabSet,
  ProductType,
  SegmentId,
  ShareClassCode,
  StatisticId,
  StatisticSuppressionSet,
  SuppressionSet,
  TabSet,
  TaxInfoConfig,
  WebProductTaxonomy,
} from '@types';
import {
  convertCsvFields,
  mergeConfig,
  splitField,
} from '@utils/data/object-utils';
import {
  getCached1YearAgoStd,
  getCached5YearAgoStd,
  getCached6MonthsAgoStd,
} from '@utils/il8n/moment-il8n';
import { Logger } from '@utils/logger';
import cloneDeep from 'lodash/cloneDeep';
import kebabCase from 'lodash/kebabCase';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { GlobalConfigService } from './global-config-service';
import { SegmentService } from './segment.service';

const logger = Logger.getLogger('SiteConfigService');

export enum BenchmarksToShow {
  ZERO = 0,
  ONE = 1,
  TWO = 2,
  ALL = Number.MAX_VALUE,
}

// this is the default list of columns to appear in the holdings table
// it can be overwritten for specific funds or asset classes in BR
export const PORTFOLIO_HOLDINGS_DEFAULT_COLS = [
  'securityName',
  'sectorName',
  'percentageOfNetAssets',
  'marketValue',
  'notionalMarketValue',
  'quantityShrpar',
];

@Injectable({
  providedIn: 'root',
})
export class SiteConfigService {
  private isPopulated$ = new BehaviorSubject<boolean>(false);
  /**
   * For now, we're just exposing config as public properties
   * TODO: replace these with methods for specific bits of state
   */

  public spaBaseUrl = '';
  public segmentId: SegmentId;
  public common: CommonConfig;
  public product: ProductConfig = {};
  public taxInfo: TaxInfoConfig;
  public literature: LiteratureConfig = {};
  public literaturePageLayoutConfig: LiteraturePageLayoutConfig;
  public literatureAudit: LiteratureAuditConfig;

  private tabsetCache: TabSet[] = [];
  private priceDistribution: PriceDistribution = {};

  constructor(
    private segmentService: SegmentService,
    private globalConfigService: GlobalConfigService,
    private appStateService: AppStateService
  ) {
    combineLatest([
      this.globalConfigService.getGlobalConfigSubject$(),
      this.segmentService.getCurrentSegmentId$(),
    ]).subscribe(this.mapConfigData);
  }

  private mapConfigData = ([globalConfig, segmentId]: [
    GlobalConfig,
    SegmentId
  ]): void => {
    logger.debug('args', globalConfig, segmentId);

    if (!segmentId) {
      segmentId = this.segmentService.getDefaultSegmentId();
    }

    const siteConfig = globalConfig.siteConfiguration;

    if (!siteConfig) {
      return; // isPopulated will remain false unless previously populated successfully
    }

    this.segmentId = segmentId;
    this.spaBaseUrl = this.appStateService.getSpaBaseUrl();
    this.common = siteConfig.site;

    if (siteConfig?.funds) {
      const genericProductConfig = cloneDeep(siteConfig.funds.all) || {};
      const segmentProductConfig = cloneDeep(siteConfig.funds[segmentId]) || {};
      this.product = convertCsvFields(
        mergeConfig(genericProductConfig, segmentProductConfig)
      );
      // pull in funds in common lists NGC-
      if (this.product.general?.activeListCommon?.length) {
        const commonFunds: FundShareClassId[][] = this.product.general.activeListCommon.map(
          (index) => splitField(siteConfig.funds.commonActiveLists[index])
        ) as FundShareClassId[][];
        this.product.general.activeList = this.product.general.activeList.concat(
          ...commonFunds
        );
      }

      if (siteConfig?.funds?.all?.priceDistribution) {
        this.priceDistribution = convertCsvFields(
          siteConfig.funds.all.priceDistribution
        );
      }
    }

    if (siteConfig?.funds?.all?.taxinfo) {
      this.taxInfo = convertCsvFields(siteConfig.funds.all.taxinfo);
    }

    if (siteConfig?.literature) {
      const genericLiteratureConfig =
        cloneDeep(siteConfig.literature?.all) || {};
      const segmentLiteratureConfig =
        cloneDeep(siteConfig.literature[segmentId]) || {};
      const literatureUrls = {
        litDetailUrl: siteConfig.literature.litdetail || '',
        litOrderCheckoutUrl: siteConfig.literature.litordercheckout || '',
        litOrderHistoryDetailUrl:
          siteConfig.literature.litorderhistorydetail || '',
        orderConfirm: siteConfig.literature.orderconfirm || '',
        orderHistoryList: siteConfig.literature.orderhistorylist || '',
        orderSuccess: siteConfig.literature.ordersuccess || '',
        orderCheckoutAddress: siteConfig.literature.ordercheckoutaddress || '',
        litListing: siteConfig.literature.litlisting || '',
        myLiteratureLandingUrl: siteConfig.literature.myliteraturelanding || '',
        downloadExcelIdentifiers_csv:
          siteConfig.literature.downloadExcelIdentifiers_csv || '',
        languageMapping_csv: siteConfig.literature.languageMapping_csv || '',
        manageEmailPreference:
          siteConfig.literature.manageEmailPreference || '',
      };
      this.literature = convertCsvFields(
        mergeConfig(genericLiteratureConfig, {
          ...segmentLiteratureConfig,
          ...literatureUrls,
        })
      );
    }

    if (siteConfig?.literatureAudit) {
      this.literatureAudit = convertCsvFields(siteConfig.literatureAudit);
    }

    logger.debug(
      'mapConfigData',
      this.segmentId,
      this.spaBaseUrl,
      this.product,
      this.literature
    );
    this.isPopulated$.next(true);
  };

  /*******************************************************************
   * helper methods
   */

  public getDefaultIdentifierKey(
    webProductTaxonomy: WebProductTaxonomy
  ): IdentifierKey {
    if (!this.product.general.keyIdentifier) {
      return null;
    }
    return this.product.general.keyIdentifier[webProductTaxonomy] || null;
  }

  /**
   * Check if override configuration present for the target fund share class.
   * If yes, fetch the new tab set and return it.
   * @param fundId fund id of the fund
   * @param shareClassCode share class code of the fund
   * @returns override tab set value as string
   */
  private getHybridFundShareClassTabSet(
    fundId: FundId,
    shareClassCode: ShareClassCode
  ): string {
    return (this.product
      .tabs as ProductTabSet)?.overrides?.find((overrideTabSet) =>
      overrideTabSet.fundShareClassList?.includes(
        `${fundId}-${shareClassCode}` as FundShareClassId
      )
    )?.tabSet;
  }

  public getFundTabSet(
    fundId: FundId,
    shareClassCode: ShareClassCode,
    overrideTabSetValue: string,
    isLitPreview = false
  ): TabSet {
    // 1. If override tab set value is coming as 'null', means it has been called from "product-detail-layout" for fund tabs.
    //    So, we need to fetch override config in this case, to be sure of new configuration.
    //    And, it can't be the default setting as step 3.2.
    //    For other cases, either it happens to be a "valid value" or 'undefined' (i.e., not having override config).
    if (overrideTabSetValue === null) {
      overrideTabSetValue = this.getHybridFundShareClassTabSet(
        fundId,
        shareClassCode
      );
    }

    // 2. Get tab config for ProductType
    const productSet: ProductTabSet = this.product.tabs;
    if (!productSet) {
      logger.info('No tabsets configured');
      return null;
    }

    // 3. Calculate set name, by looping assignments
    let setName = 'default';

    // ********** WDE-4843 - Configure tab set override **********
    // 3.1 If valid value, then set the "new tab set" value to fetch the page link later.
    if (overrideTabSetValue) {
      setName = overrideTabSetValue;
    }
    // 3.2 Else, just fetch the tab set it belongs to, from 'assignments' list.
    else if (productSet.assignments) {
      setName = Object.entries(productSet.assignments) // turn assignments object into an array
        .reduce((name, [key, funds]) => {
          return funds.includes(fundId) ? key : name;
        }, setName);
    }

    // 4. for lit-preview page we use predefined set with documents only for all funds
    if (isLitPreview) {
      setName = 'lit-preview';
    }

    // 5. Get correct TabSet
    const tabSet: TabSet = productSet.sets.find(
      (set: TabSet): boolean => setName === set.name
    );
    if (!tabSet) {
      logger.info(`No tabset configured for ${this.segmentId} ${setName}`);
      return null;
    }

    return tabSet;
  }

  /**
   * returns an absolute link for a fund's share class.
   * The FundId is required!
   * Other params will be added to the url if they are passed
   */
  public getFundLink(
    fundId: FundId,
    shareClassCode: ShareClassCode,
    fundName?: string,
    identifier?: FundIdentifier,
    isLitPreview = false
  ): string {
    // 1. Get TabSet
    // 1a. Get override tab set value, if present for this current fund share class id.
    const overrideTabSetValue: string = this.getHybridFundShareClassTabSet(
      fundId,
      shareClassCode
    );

    // 1b. cache added, so when we read 1500 times FundLink on PPSS page we don't have to process/find tabset 1500 times
    const tabSetCacheKey = this.getTabSetCacheKey(
      fundId,
      shareClassCode,
      !!overrideTabSetValue,
      isLitPreview
    );

    let tabSet: TabSet;
    if (tabSetCacheKey in this.tabsetCache) {
      tabSet = this.tabsetCache[tabSetCacheKey];
    } else {
      tabSet = this.getFundTabSet(
        fundId,
        shareClassCode,
        overrideTabSetValue,
        isLitPreview
      );
      this.tabsetCache[tabSetCacheKey] = tabSet;
    }

    if (!tabSet) {
      return null;
    }

    // 2. Get base link from TabSet
    let link: string = tabSet.page;
    if (!link) {
      return null;
    }

    // 3. add spaBaseUrl prefix
    link = this.spaBaseUrl + link;

    // 4. Add FundId
    link = `${link}/${fundId}`;

    // 5. Optionally add ShareClassCode
    if (shareClassCode) {
      link = `${link}/${shareClassCode}`;
    }

    // 6. Add fundName in kebab case for SEO
    if (fundName) {
      link = `${link}/${kebabCase(fundName)}`.replace(
        'clear-bridge',
        'clearbridge'
      );
    }

    // 7. Optionally add default Identifier for SEO
    // TODO: should this be kebabcase too?
    if (identifier) {
      link = `${link}/${identifier}`;
    }
    return link;
  }

  /**
   * Returns cache key for tabset
   * @param isOverrideConfigPresent if current fund id and share class code has override config
   */
  private getTabSetCacheKey(
    fundId: FundId,
    shareClassCode: ShareClassCode,
    isOverrideConfigPresent: boolean,
    isLitPreview = false
  ) {
    // If override config present, then we have to save tab set as fund share class level in cache.
    if (isOverrideConfigPresent) {
      return (
        fundId +
        `_${shareClassCode}` +
        (isLitPreview ? '_1_' : '_0_') +
        this.segmentId
      );
    }

    // Else, fund level caching is sufficient, as before.
    return fundId + (isLitPreview ? '_1_' : '_0_') + this.segmentId;
  }

  public getFundSectionLinks(
    fundId: FundId,
    shareClassCode?: ShareClassCode,
    fundName?: string,
    identifier?: FundIdentifier
  ): FundSectionLink[] {
    const baseLink: string = this.getFundLink(
      fundId,
      shareClassCode,
      fundName,
      identifier
    );
    if (!baseLink) {
      return null;
    }

    // Get override tab set value, if present for this current fund share class id.
    const overrideTabSetValue: string = this.getHybridFundShareClassTabSet(
      fundId,
      shareClassCode
    );

    const tabSetCacheKey = this.getTabSetCacheKey(
      fundId,
      shareClassCode,
      !!this.getHybridFundShareClassTabSet(fundId, shareClassCode)
    );
    let tabSet: TabSet = this.tabsetCache[tabSetCacheKey];

    if (!tabSet) {
      tabSet = this.getFundTabSet(fundId, shareClassCode, overrideTabSetValue);
      this.tabsetCache[tabSetCacheKey] = tabSet;
    }

    return tabSet.tabs.map(
      (tab: string): FundSectionLink => ({
        link: `${baseLink}#${tab}`,
        // TODO: if this was a service, could use the translate service
        labelKey: `products.section-tab-${tab}`,
      })
    );
  }

  public isActiveShareClass(shareClassKey: FundShareClassId): boolean {
    return this.product.general.activeList.includes(shareClassKey);
  }

  public isSoftLaunchShareClass(shareClassKey: FundShareClassId): boolean {
    return this.product.general.softLaunchList.includes(shareClassKey);
  }

  public isSoftClosedShareClass(shareClassKey: FundShareClassId): boolean {
    return this.product.general.softClosedList?.includes(shareClassKey);
  }

  /**
   * Indicates if ShareClass should appear in PPSS etc i.e is either Active or SoftLaunch
   */
  public isDisplayedShareClass(
    shareClassKey: FundShareClassId,
    includeSoftClosed = false
  ): boolean {
    return (
      this.isActiveShareClass(shareClassKey) ||
      this.isSoftLaunchShareClass(shareClassKey) ||
      (includeSoftClosed
        ? this.isSoftClosedShareClass(shareClassKey)
        : includeSoftClosed)
    );
  }

  // TODO: define productType type
  public getFundContentCategories(
    productType: string,
    location: string
  ): string[] {
    return this.product.overview[productType]?.fundContent?.find(
      (cats) => cats.location === location
    )?.categories;
  }

  public getRiskFactorContentCategory() {
    return this.product.overview.riskFactorContentCategory;
  }

  /**
   * get Regulator Link URL from risk factor BR
   * @returns string
   */
  public getRegulatorLinkUrl(): string {
    return this.product.overview?.regulatorLinkURL;
  }

  /**
   * [MiFID Rule]
   * Check the configuration to suppress performance data for funds less than one year old.
   * Show EMDASH on template whereever this methods evaluates to true.
   * @returns boolean flag value
   */
  public showEMDashForUnderOneYearOldFund(): boolean {
    return this.product?.general.showMDashForUnder1Year === true;
  }

  /**
   * Check the configuration to suppress performance data for funds less than six months old.
   * Show EMDASH on template whereever this methods evaluates to true.
   * @returns boolean flag value
   */
  public showEMDashForUnderSixMonthsOldFund(): boolean {
    return this.product?.general.showMDashForUnder6Months === true;
  }

  public mustHidePerformanceData(
    performanceDateStd: string,
    isLoggedIn: boolean = false
  ): boolean {
    let hidePerformanceData = false;
    const sixMonthsAgoStd = getCached6MonthsAgoStd();
    const oneYearAgoStd = getCached1YearAgoStd();
    if (this.product?.general.showMDashForUnder1Year) {
      hidePerformanceData = this.hidePerformanceDataRule(
        performanceDateStd,
        isLoggedIn,
        oneYearAgoStd
      );
    } else if (this.product?.general.showMDashForUnder6Months) {
      hidePerformanceData = this.hidePerformanceDataRule(
        performanceDateStd,
        isLoggedIn,
        sixMonthsAgoStd
      );
    }
    return hidePerformanceData;
  }

  // to override supressPerformance data for loggedin user on CA
  private hidePerformanceDataRule(
    performanceDateStd: string,
    isLoggedIn: boolean,
    cachedStdDate: string
  ): boolean {
    return this.product.general?.displayPerformanceDataForUnder1Year &&
      isLoggedIn &&
      this.isCanada()
      ? false
      : performanceDateStd > cachedStdDate;
  }

  /**
   * Only applies to Germany+Austria
   * Returns value indicating if fund is less than 1, 1 to 5 or over 5 years old
   * All other sites returns null
   */
  public getMifidAge(inceptionDateStd: string): MifidAge {
    if (this.isSiteDynamicLabels()) {
      if (inceptionDateStd < getCached5YearAgoStd()) {
        return MifidAge.OVER_FIVE;
      } else if (inceptionDateStd > getCached1YearAgoStd()) {
        return MifidAge.UNDER_ONE;
      } else {
        return MifidAge.ONE_TO_FIVE;
      }
    }
    return null;
  }

  public hideRiskStatisticsBenchmark(): boolean {
    return this.product.performance?.hideRiskStatisticsBenchmark
      ? this.product.performance.hideRiskStatisticsBenchmark
      : false;
  }

  public hidePerformanceCurrency(
    webProductTaxonomy: WebProductTaxonomy
  ): boolean {
    webProductTaxonomy = this.normalize529ProductType(webProductTaxonomy);

    return this.product.performance.hideCurrency
      ? this.product.performance.hideCurrency.includes(webProductTaxonomy)
      : false;
  }
  public getBenchmarksToShowOnOverview(fundId: FundId): number {
    if (this.product.overview.showAllAssociatedBenchmarkInfo) {
      return BenchmarksToShow.ALL;
    } else {
      return this.getMaxNumberOfBenchmarksToShow(fundId);
    }
  }

  public getMaxNumberOfBenchmarksToShow(fundId: FundId): number {
    if (this.product.general.noBenchmarkList?.includes(fundId)) {
      return BenchmarksToShow.ZERO;
    }
    if (this.product.general.twoBenchmarkList?.includes(fundId)) {
      return BenchmarksToShow.TWO;
    }
    if (this.product.general.allBenchmarkList?.includes(fundId)) {
      return BenchmarksToShow.ALL;
    }
    return BenchmarksToShow.ONE;
  }

  public getBenchmarkOrder(webProductTaxonomy: WebProductTaxonomy): string[] {
    webProductTaxonomy = this.normalize529ProductType(webProductTaxonomy);
    if (
      this.product.performance.benchmarkOrder &&
      webProductTaxonomy in this.product.performance.benchmarkOrder &&
      this.product.performance.benchmarkOrder[webProductTaxonomy].length
    ) {
      return this.product.performance.benchmarkOrder[webProductTaxonomy];
    }
    return ['INST1', 'INST2', 'INST3', 'INST4', 'INST5'];
  }

  /**
   * checks if element name is present on supressionList
   * validates if the same element with prefix PUBLIC_ is available on supression list for non logged user only
   * @param webProductTaxonomy configuration section name
   * @returns comma separated list of suprressed elements
   */
  public getSuppressedFundInfoElements(
    webProductTaxonomy: WebProductTaxonomy
  ): string[] {
    webProductTaxonomy = this.normalize529ProductType(webProductTaxonomy);
    if (
      this.product.overview.fundInfoSuppressionList &&
      webProductTaxonomy in this.product.overview.fundInfoSuppressionList
    ) {
      return this.product.overview.fundInfoSuppressionList[webProductTaxonomy];
    }
    return [];
  }

  public getSuppressedBenchmarks(
    webProductTaxonomy: WebProductTaxonomy
  ): string[] {
    webProductTaxonomy = this.normalize529ProductType(webProductTaxonomy);
    if (
      this.product.performance.suppressBenchmarkData &&
      webProductTaxonomy in this.product.performance.suppressBenchmarkData
    ) {
      return this.product.performance.suppressBenchmarkData[webProductTaxonomy];
    }
    return [];
  }

  /**
   *
   * @param fundFamily fundFamily
   * @returns if supressing POP data if proivded by config it will verify for the fundfamily
   */
  public getSuppressedPOP(fundFamily: string): boolean {
    if (this.product.performance.suppressPOPRow) {
      return this.product.performance.suppressPOPRow.indexOf(fundFamily) > -1;
    }
    return false;
  }

  /**
   *
   * @param fundFamily fundFamily
   * @returns if supressingdata for 6m15yr is proivded by config it will verify for the fundfamily
   */
  public getSuppressed6m15Y(fundFamily: string): boolean {
    /**
     * hardcoding it once BR changes willbe available will remove it
     */

    if (this.product.performance.suppress6m15yrPerformance) {
      return (
        this.product.performance.suppress6m15yrPerformance.indexOf(fundFamily) >
        -1
      );
    }
    return false;
  }
  public hideAdditionalShareClassLinks(
    webProductTaxonomy: WebProductTaxonomy
  ): boolean {
    webProductTaxonomy = this.normalize529ProductType(webProductTaxonomy);

    return this.product.performance.hideAddShLinkList
      ? this.product.performance.hideAddShLinkList.includes(webProductTaxonomy)
      : false;
  }

  public showPeerGroupCategoryAverageAddition(
    webProductTaxonomy: WebProductTaxonomy
  ): boolean {
    webProductTaxonomy = this.normalize529ProductType(webProductTaxonomy);
    return this.product?.performance?.showPeerGroupCategoryAverageAddition?.includes(
      webProductTaxonomy
    );
  }

  public showRatings(productType: ProductType): boolean {
    if (
      this.product.ppss.showRatings &&
      productType in this.product.ppss.showRatings
    ) {
      return this.product.ppss.showRatings[productType];
    }
    return false;
  }

  public showExchangeRate(): boolean {
    return this.product.ppss.enableExchangeRate;
  }

  public showOverviewRatings(
    webProductTaxonomy: WebProductTaxonomy,
    type: string
  ): boolean {
    webProductTaxonomy = this.normalize529ProductType(webProductTaxonomy);
    if (
      this.product.overview.ratings &&
      webProductTaxonomy in this.product.overview.ratings
    ) {
      return this.product.overview.ratings[webProductTaxonomy].includes(type);
    }
    return false;
  }

  public showYields(productType: ProductType): boolean {
    if (
      this.product.ppss.showYields &&
      productType in this.product.ppss.showYields
    ) {
      return this.product.ppss.showYields[productType];
    }
    return false;
  }

  /**
   * It will return the show article 6 status from site config
   */
  public showArticle6(productType: ProductType): boolean {
    if (
      this.product.ppss.showArticle6 &&
      productType in this.product.ppss.showArticle6
    ) {
      return this.product.ppss.showArticle6[productType];
    }
    return false;
  }

  public hideNavChange(productType: ProductType): boolean {
    if (
      this.product.ppss.hideNavChange &&
      productType in this.product.ppss.hideNavChange
    ) {
      return this.product.ppss.hideNavChange[productType];
    }
    return false;
  }

  public canToggleNAVColumns = (productType: ConfigurationId): boolean =>
    ['US', 'CA'].includes(this.getPdsCountry()) &&
    [ConfigurationId.CEF, ConfigurationId.ETF].includes(productType);

  public hideFactsheet(productType: ProductType): boolean {
    if (
      this.product.ppss.hideFactsheet &&
      productType in this.product.ppss.hideFactsheet
    ) {
      return this.product.ppss.hideFactsheet[productType];
    }
    return false;
  }

  public showIdentifiers(productType: ProductType): boolean {
    if (
      this.product.ppss.showIdentifiers &&
      productType in this.product.ppss.showIdentifiers
    ) {
      return this.product.ppss.showIdentifiers[productType];
    }
    return false;
  }

  public showDownloadExcelButton(productType: ProductType): boolean {
    if (
      this.product.ppss.showExcelDownload &&
      productType in this.product.ppss.showExcelDownload
    ) {
      return this.product.ppss.showExcelDownload[productType];
    }
    return false;
  }

  public getFundIdentifiers(productType: ProductType): string[] {
    if (
      this.product.ppss.fundIdentifiers &&
      productType in this.product.ppss.fundIdentifiers
    ) {
      return this.product.ppss.fundIdentifiers[productType];
    }
    return [];
  }

  /**
   * Fetch the config from site common, if favorite funds is enabled.
   * This config isn't specific to any product type.
   */
  public isFavoriteFundsEnabled(): boolean {
    return this.common.enableFavoriteFunds;
  }

  /**
   * Return boolean flag as per config received from BR against specific product type.
   * Default being false, if config not received too.
   * @param productType product type received from component rendered
   */
  public showFavoritesOnly(productType: ProductType): boolean {
    if (
      this.product.ppss.showFavoriteToggle &&
      productType in this.product.ppss.showFavoriteToggle
    ) {
      return this.product.ppss.showFavoriteToggle[productType];
    }

    return false;
  }

  /**
   * Create performance view option array.
   * @param performanceConfig ppss component configuration
   */
  public getPerformanceViewOptions(
    productType: ProductType
  ): PerformanceViewOption[] {
    const performanceConfig: PerformanceConfig = this.product?.performance;
    const dataRefreshRate = performanceConfig.dataRefreshRate[productType];
    const performanceTypeConfigs: PerformanceType[] =
      performanceConfig.ppssPerformanceViews[productType];

    const performanceViewOptions: PerformanceViewOption[] = performanceTypeConfigs.map(
      (perfType) => ({
        name: perfType,
        labelKey: `products.perf-view-${kebabCase(perfType)}`,
        headerLabelKey: `products.perf-view-header-${kebabCase(perfType)}`,
        periodEnds: this.decodePeriodEnds(dataRefreshRate[perfType]),
        isDefault: false,
      })
    );

    if (performanceViewOptions.length > 0) {
      performanceViewOptions[0].isDefault = true; // set a default
    }
    return performanceViewOptions;
  }

  public getPeriodEnds(
    productType: ProductType,
    performanceType: PerformanceType
  ): string[] {
    if (
      this.product.performance.dataRefreshRate &&
      productType in this.product.performance.dataRefreshRate
    ) {
      const dataRefreshRate: Record<PerformanceType, string> = this.product
        .performance.dataRefreshRate[productType];
      if (performanceType in dataRefreshRate) {
        return this.decodePeriodEnds(dataRefreshRate[performanceType]);
      } else {
        return [];
      }
    }
    return [];
  }

  /**
   * Return the performance period code as their respective name array.
   * @param peCode period code
   */
  public decodePeriodEnds(peCode: string): PerformanceView[] {
    switch (peCode) {
      case 'MQ':
        return [PerformanceView.MONTHLY, PerformanceView.QUARTERLY];
      case 'QM':
        return [PerformanceView.QUARTERLY, PerformanceView.MONTHLY];
      case 'Q':
        return [PerformanceView.QUARTERLY];
      case 'M':
        return [PerformanceView.MONTHLY];
      default:
        return [];
    }
  }

  public getIsPopulated$(): Observable<boolean> {
    return this.isPopulated$.asObservable();
  }

  public getPdsCountry(): ConfigurationCountry {
    return this.product.site?.country;
  }

  public getPdsLanguage(): string {
    return this.product.site?.language;
  }

  // WDE-5883 This is a temprory fix to reduce the font size of the caveats for the specific countries
  public isSmallLegalCaveats(): boolean {
    return this.getPdsCountry() === 'AU';
  }

  /*******************************************************************
   * Portfolio Config helpers
   */

  public isPortfolioBenchmarkSuppressed(fundId: FundId): boolean {
    const isSuppressed: boolean = this.product.portfolio?.benchmarkSuppression.includes(
      fundId
    );
    if (isSuppressed) {
      logger.debug(`Benchmark suppressed for fundId '${fundId}'`);
    }
    return isSuppressed;
  }

  /**
   * If the component is suppressed, the apprioriate supression set will be returned
   * if not suppressed, this will return null
   */
  public getPortfolioComponentSuppressionSet(
    componentId: PortfolioComponentId,
    fundId: FundId,
    assetClass: AssetClass
  ): SuppressionSet {
    // return first set that matches componentId AND (fundId OR assetClass)
    const suppressionSet: SuppressionSet = this.product.portfolio?.suppressionSets.find(
      (set: SuppressionSet): boolean =>
        set.portfolioComponents.includes(componentId) &&
        (set.funds.includes(fundId) || set.assetClasses.includes(assetClass))
    );
    if (suppressionSet) {
      const reason = suppressionSet.funds.includes(fundId)
        ? `fundId '${fundId}'`
        : `asset class '${assetClass}'`;
      logger.debug(
        `Portfolio Component '${componentId}' is suppressed by set '${suppressionSet.id}' due to ${reason}`
      );
      return suppressionSet;
    }

    // Component not suppressed, so return null
    return null;
  }

  /**
   * If the component is suppressed, the apprioriate supression set will be returned
   * if not suppressed, this will return null
   */
  public getPerformanceComponentSuppressionSet(
    componentId: PerformanceComponentId,
    fundId: FundId
  ): PerformanceSuppressionSet {
    // return first set that matches componentId AND fundId
    const suppressionSet: PerformanceSuppressionSet = this.product.performance?.suppressionSets?.find(
      (set: PerformanceSuppressionSet): boolean =>
        set.performanceChart.includes(componentId) && set.funds.includes(fundId)
    );
    if (suppressionSet) {
      logger.debug(
        `Performace Component '${componentId}' is suppressed by set '${suppressionSet.id}' due to fundId '${fundId}'`
      );
      return suppressionSet;
    }

    // Component not suppressed, so return null
    return null;
  }

  public isPortfolioStatisticSuppressedBySite(
    statisticId: StatisticId
  ): boolean {
    if (this.product.portfolio?.suppressStatistics?.includes(statisticId)) {
      // check if this statistics is suppressed for whole site
      logger.debug(`Statistic '${statisticId}' suppressed for entire site`);
      return true;
    }
    return false;
  }

  public getPortfolioStatisticSuppressionSet(
    statisticId: StatisticId,
    fundId: FundId,
    isLoggedIn: boolean
  ): StatisticSuppressionSet {
    // return first set that matches statisticId AND fundId
    const suppressionSet: StatisticSuppressionSet = this.product.portfolio?.statisticSuppressionSets.find(
      (set: StatisticSuppressionSet): boolean =>
        set.funds.includes(fundId) &&
        (set.statistics.includes(statisticId) ||
          (set.statistics.includes(('PUBLIC_' + statisticId) as StatisticId) &&
            !isLoggedIn))
    );
    if (suppressionSet) {
      logger.debug(
        `Portfolio Statistic '${statisticId}' is suppressed by set '${suppressionSet.id}' due to fundId '${fundId}`
      );
      return suppressionSet;
    }
    return null;
  }

  /**
   * matches column sets in this order:
   * 1. matching fundId
   * 2. matching Asset Class
   * 3. default column set
   */
  public getHoldingsTableColumns(
    fundId: FundId,
    assetClass?: AssetClass
  ): string[] {
    logger.debug(
      'portfolioHoldingDataPoints',
      this.product.portfolio?.portfolioHoldingDataPoints
    );
    // defaults colSets to empty array if none in config
    const colSets: HoldingTableColumns[] =
      this.product.portfolio?.portfolioHoldingDataPoints || [];

    // returns PORTFOLIO_HOLDINGS_DEFAULT_COLS if matching colSet not found
    return (
      colSets.find(
        (colSet: HoldingTableColumns): boolean =>
          colSet.funds.includes(fundId) ||
          colSet.assetClasses.includes(assetClass)
      )?.holdings || PORTFOLIO_HOLDINGS_DEFAULT_COLS
    );
  }

  public getCumulativeChartCalculationType(): ChartCalculationType[] {
    switch (this.product.performance?.cumulativeHypoChart) {
      case 'BOTH':
        return [
          ChartCalculationType.HUNDRED_REBASE_POINTS,
          ChartCalculationType.TENK_GROWTH,
        ];
      case '100 rebase':
        return [ChartCalculationType.HUNDRED_REBASE_POINTS];
      case '10k':
        return [ChartCalculationType.TENK_GROWTH];
      default:
        return [];
    }
  }

  public isSiteInternational(): boolean {
    return this.getPdsCountry() !== 'US';
  }
  public isAustralia(): boolean {
    return this.getPdsCountry() === 'AU';
  }

  public isSiteUcits(): boolean {
    return this.getPdsCountry() !== 'US' && this.getPdsCountry() !== 'CA';
  }

  // returns true if site should have dynamic mifid labels i.e. Austria or Germany
  public isSiteDynamicLabels(): boolean {
    return this.product?.performance?.dynamicLabels;
  }

  // temit comes under channel section instead of geographical location
  public isTemit(): boolean {
    return this.appStateService.getChannel() === Channel.TEMIT;
  }

  // martin currie gpt comes under channel section instead of geographical location
  public isMartinCurrieGpt(): boolean {
    return this.appStateService.getChannel() === Channel.MARTIN_CURRIE_GPT;
  }

  // primerica comes under channel section instead of geographical location
  public isPrimerica(): boolean {
    return this.appStateService.getChannel() === Channel.PRIMERICA;
  }

  public isCanada(): boolean {
    return this.getPdsCountry() === 'CA';
  }

  public isLuxembourg(): boolean {
    return this.getPdsCountry() === 'LU';
  }

  public isNjBest(): boolean {
    return (
      this.getPdsCountry() === 'US' &&
      this.common.taxonomyLanguage === 'english---njbest'
    );
  }

  public isSouthAfrica(): boolean {
    return this.getPdsCountry() === 'ZA';
  }

  public isOffshore(): boolean {
    return this.getPdsCountry() === 'NRC';
  }

  public isGermany(): boolean {
    return this.getPdsCountry() === 'DE';
  }

  public showPop() {
    return !this.isSiteInternational() || this.getPdsCountry() === 'AU';
  }

  public showApplicationPrice() {
    return this.getPdsCountry() === 'AU';
  }

  public showRedemptionPrice() {
    return this.getPdsCountry() === 'AU';
  }

  public isSgHk() {
    return (
      this.getPdsCountry() === 'SG' ||
      this.getPdsCountry() === 'HK' ||
      this.getPdsCountry() === 'MY'
    );
  }

  public isBrazil(): boolean {
    return this.getPdsCountry() === 'BR';
  }

  public getDefaultCalcType(productType: ProductType): CalcTypeStd {
    if (
      this.product.general.defaultCalcType &&
      productType in this.product.general.defaultCalcType
    ) {
      const calcType = this.product.general.defaultCalcType[productType].trim();
      return calcType !== '' ? calcType : null;
    }
    return null;
  }

  public getAdditionalCalcType(productType: ProductType): string {
    if (
      this.product.general.additionalCalcType &&
      productType in this.product.general.additionalCalcType
    ) {
      const calcType = this.product.general.additionalCalcType[
        productType
      ].trim();
      return calcType !== '' ? calcType : null;
    }
    return null;
  }

  /**
   * Returns GlobalConfig Site configuration
   */
  public getGlobalConfig(): GlobalConfig {
    return this.globalConfigService?.config;
  }

  public isTraded(fundId: FundId): boolean {
    return fundId && !this.product.general.nonTradedFunds?.includes(fundId);
  }

  /**
   * NGC-12770: Fund managers bios visibility condition.
   * Return boolean as flag present in 'showLinkToBios' in BR config against given taxonomy.
   * @param webProductTaxonomy valid value from pds feed
   */
  public isShowLinkToBiosStatusValidForFundManagers(
    webProductTaxonomy: WebProductTaxonomy
  ): boolean {
    webProductTaxonomy = this.normalize529ProductType(webProductTaxonomy);
    if (this.product.overview.showLinkToBios?.[webProductTaxonomy]) {
      return true;
    }

    return false;
  }

  public isShowYearsOfExperienceValidForFundManagers(
    webProductTaxonomy: WebProductTaxonomy
  ): boolean {
    webProductTaxonomy = this.normalize529ProductType(webProductTaxonomy);
    if (this.product.overview.showYearsOfExperience?.[webProductTaxonomy]) {
      return true;
    }

    return false;
  }

  /**
   * ********** NGC-13646: SA - performance currency list. **********
   */

  // for now, only South Africa has Currency dropdown on PPSS Performance
  public showPerformanceCurrency = (): boolean => this.getPdsCountry() === 'ZA';

  /**
   * Determines if Login Form should be hidden on sign-in forms
   */
  public hideLoginForm(): boolean {
    return (
      this.common?.hideLoginForm ||
      this.appStateService.getAuthenticationType() ===
        AuthenticationType.ONE_FRANKLIN_ID_US
    );
  }

  /**
   * Determines if Sign Widget should be hidden on sign-in forms
   */
  public hideSignWidget(): boolean {
    return this.common?.hideSignInWidget;
  }

  public shouldShowAdditionalInfoOnSignInForm(): boolean {
    return (
      this.getPdsCountry() === 'DE' ||
      this.getPdsCountry() === 'AT' ||
      this.getPdsCountry() === 'CZ' ||
      this.getPdsCountry() === 'CA'
    );
  }
  /**
   * Get all the filter options from BR for tax info page
   */
  public getTaxInfoFilterOptions(): string[] {
    return this.taxInfo?.filters || [];
  }

  /**
   * Get all product type filter options from BR for tax info page
   */
  public getTaxInfoProductTypeFilterOptions(): string[] {
    return this.taxInfo?.productTypefilters || [];
  }

  public getLiteratureAudit(): LiteratureAuditConfig {
    return this.literatureAudit;
  }

  public getLoginMaintenanceSettings(): LoginMaintenanceSettings {
    return {
      showNotice: this.common?.loginMaintenanceNotice || false,
      noticeText: this.common?.loginMaintenanceNoticeText || '',
    };
  }

  /**
   * WDE-4201 CPREIF quick fixes
   * These methods are checking the product type TOF now
   * TODO: in future these should come from BR config
   */

  // Hide NAV Summary Item for CPREIF and TOF(product type) ALL Share Classes
  public hideSummaryItemNav(productType: string): boolean {
    return productType === ConfigurationId.TOF;
  }

  // Hide POP Summary Item for CPREIF and TOF(product type) ALL Share Classes
  public hideSummaryItemPop(productType: string): boolean {
    return (
      productType === ConfigurationId.TOF || productType === ConfigurationId.CIT
    );
  }

  // Show 3yr Annualized Return Summary Item for CPREIF and TOF(product type) ALL Share Classes
  public showSummaryItem3YearAnnual(productType: string): boolean {
    return productType === ConfigurationId.TOF;
  }

  // Show as-of date on NAV YTD Summary Item for CPREIF and TOF(product type) ALL Share Classes
  public showSummaryItemNavYtdAsOfDate(productType: string): boolean {
    return productType === ConfigurationId.TOF;
  }

  // Show Distribution Rate at NAV Summary Item for CPREIF and TOF(product type) ALL Share Classes
  public showSummaryItemDistributionRateAtNav(
    productType: string,
    fundId: FundId
  ): boolean {
    return (
      productType === ConfigurationId.TOF &&
      // DCE-3734: Suppress when its TOF & showNav flag is true.
      !this.showNavDataOnlyInTOFFundHeader(fundId)
    );
  }

  // Hide Performance POP table row for CPREIF and TOF(product type) I and D share classes
  public hidePerformanceTableRowPop(
    shareClassCode: ShareClassCode,
    productType: string
  ): boolean {
    return (
      productType === ConfigurationId.TOF && ['D', 'I'].includes(shareClassCode)
    );
  }

  // Hide POP in pricing table and daily fund prices in pricing tab for TOF(product type) I and D share classes
  public hidePopHistoricalPricingTable(
    shareClassCode: ShareClassCode,
    productType: string
  ): boolean {
    return (
      productType === ConfigurationId.TOF && ['D', 'I'].includes(shareClassCode)
    );
  }

  public hideDistributionPerShare(fundId: FundId): boolean {
    return (
      this.priceDistribution?.hideDistributionPerShare &&
      this.priceDistribution?.funds.includes(fundId)
    );
  }

  // get list of MVP product types supported by US Servicing
  // TODO: currently use channel json, but in future could use BR Site Config for instant editing
  public getUSServicingProducts(): ProductType[] {
    return this.appStateService.getUSServicingProducts();
  }

  public hideRatesAndYieldsPerFund(fundId: FundId): boolean {
    return (
      this.priceDistribution?.hideRatesAndYieldsForSelectedFunds &&
      this.priceDistribution?.funds.includes(fundId)
    );
  }

  /**
   * Returns whether to hide percent change on nav on chart
   * @returns true/false
   */
  public hidePercentChangeNavOnChart(): boolean {
    // TODO :: 'price-distribution' key for segment is not consistent with 'priceDistribtution' key
    // of 'all' funds so we are exclusively pulling the key from 'price-distribution'
    // this needs to be consistent from BR
    const isHidePercentChangeOnChart = this.product['price-distribution']
      ?.hidePercentChangeNavOnChart;
    if (isHidePercentChangeOnChart) {
      return isHidePercentChangeOnChart;
    }
    return false;
  }

  // get the fundids from the active list
  public getActiveListFundIds(): FundId[] {
    const fundIds: FundId[] = [];
    this.product.general.activeList.forEach(
      (fundShareClassId: FundShareClassId) => {
        fundIds.push(fundShareClassId.split('-', 1)[0] as FundId);
      }
    );
    return [...new Set(fundIds)];
  }
  /**
   * Sets YTD Nav flag to true when checked
   * @returns boolean value
   */
  public showYTDAtNAV(): boolean {
    return this.product.general?.ytdTotalReturnNavMarketPrice;
  }

  // update the active list of product based on the firm specific
  public updateActiveListFunds(
    activeList: FundShareClassId[],
    hasProductPageLinks = true
  ): void {
    if (hasProductPageLinks) {
      this.product.general.activeList = [...activeList];
    } else {
      this.product.general.activeList = [];
      this.product.general.softLaunchList = [...activeList];
    }
  }

  // held products are automatically included in active list
  public addFundToActiveList(
    onetis: FundShareClassId,
    hasProductPageLinks
  ): boolean {
    const list = hasProductPageLinks
      ? this.product.general?.activeList
      : this.product.general?.softLaunchList;
    if (list && !list.includes(onetis)) {
      list.push(onetis);
      return true;
    }
    return false;
  }

  public normalize529ProductType(
    productType: WebProductTaxonomy
  ): WebProductTaxonomy {
    // see gw-cms-ui-extensions\src\app\services\site-fund-options.service.ts line 198
    return productType === '529' ? ('_529' as WebProductTaxonomy) : productType;
  }

  /**
   * Check if pricing history chart needs to be hidden.
   * Verify it with the given fund id in suppression list accordingly.
   * @param fundId given fund id
   */
  public hideHistoricalPricingChart(fundId: FundId): boolean {
    const suppressionList: string[] = this.priceDistribution
      .suppressHistoricalPriceChart;

    return suppressionList?.includes(fundId) ? true : false;
  }

  /**
   * Check if fund header should have only NAV details to be shown.
   * Verify it with the given fund id in the list accordingly.
   * @param fundId given fund id
   */
  public showNavDataOnlyInTOFFundHeader(fundId: FundId): boolean {
    const fundIdList: string[] = this.product.overview
      .showNavDataOnlyInTOFFundHeader;

    return fundIdList?.includes(fundId) ? true : false;
  }
}
